import {ERROR, SET_USER_DATA, START, SUCCESS, USER_DATA_REQUEST} from "../../../../constants/action-types";
import {BASE_PARAMS} from "../../../../constants/api";
import {api} from "../../../../App";
import {resetAuthData} from "../../Auth/actions";

const userDataRequestStart = () => ({
    type: `${USER_DATA_REQUEST}${START}`,
});

const userDataRequestSuccess = (data) => ({
    type: `${USER_DATA_REQUEST}${SUCCESS}`,
    payload: data
});

const userDataRequestError = (err) => ({
    type: `${USER_DATA_REQUEST}${ERROR}`,
    payload: err
});

export const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: data
});


export const fetchUserData = () => (dispatch, getState) => {

    const authData = getState().auth;
    dispatch(userDataRequestStart());

    const params = {
        ...BASE_PARAMS,
        action: 'applicant.testschedule',
        base: authData.base,
        applicant: authData.applicant,
        otp: authData.otp
    };

    api.get('/', {params})
        .then(res => {
            dispatch(userDataRequestSuccess(res.data.Answer.Data[0]))
        })
        .catch(err => {
            dispatch(userDataRequestError(err));
            dispatch(resetAuthData());
        })
};