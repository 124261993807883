import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from '../tasks.module.css';
import {Button, FormInput} from "shards-react";

const SimpleType = ({ sendAnswer }) => {
//answers=[{answer:"ответ"}]
    const [answer, setAnswer] = useState('');

    const onChange = (e) => {
        setAnswer(e.target.value);
    };

    const onSend = () => {
        sendAnswer({
            answers: JSON.stringify([{answer}])
        })
    };

    return (
        <div className={styles.answer_with_input}>
            <div className={styles.flex}>
                <FormInput placeholder="Введите ответ" onChange={onChange} value={answer} className={'mr-2'}/>
                <Button onClick={onSend}>Ответить</Button>
            </div>
        </div>
    );
};

SimpleType.propTypes = {};

export default SimpleType;