import axios from 'axios';
import {BASE_URL} from "../../constants/api";



export const configureApi = (store) => axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
});

