import {SET_USER_DATA, SUCCESS, USER_DATA_REQUEST} from "../../../../constants/action-types";

    const initialState = null;



export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${USER_DATA_REQUEST}${SUCCESS}`:
            return action.payload;

        case SET_USER_DATA:
            return action.payload;

        default:
            return state;
    }
};