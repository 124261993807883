import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../../utils/Modal'
import styles from './SecureCode.module.css'
import { Button } from 'shards-react'
import { useHistory } from 'react-router-dom'
import { BASE_PARAMS } from '../../../../../constants/api'
import { api } from '../../../../../App'
import { createNotification } from '../../../../../helpers/createNotification'
import InputMask from 'react-input-mask'

const SecureModal = ({ closeModal, setUserData, authData, user }) => {
  const [room, setRoom] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  let history = useHistory()

  const onChange = e => {
    setRoom(e.target.value)
  }

  const checkRoom = room => {
    if (!room || room.length !== 4 || isNaN(Number(room))) {
      return createNotification('error', 'Неверный код доступа к собеседованию.')
    }
    if (user) {
      const params = {
        ...BASE_PARAMS,
        action: 'applicant.checkcode',
        base: authData.base,
        otp: authData.otp,
        applicant: authData.applicant,
        schedule: user ? user.schedule_id : '',
        code: room,
      }
      setIsLoading(true)
      api
        .get('/', { params })
        .then(res => {
          if (res.data.Answer.Result === 'Error') {
            createNotification('error', 'Неверный код доступа к собеседованию.')
          } else {
            setUserData({ ...user, access_code: room })
            history.push('/enrollment')
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => setIsLoading(false))
    }
  }

  return (
    <Modal closeModal={closeModal}>
      <div className={'text-center'}>Введите проверочный код, чтобы начать собеседование</div>
      <div className={'text-center'}>
        <InputMask mask='9999' value={room} onChange={onChange} autoFocus>
          {inputProps => (
            <input type='text' className={styles.code} placeholder={'____'} value={room} onChange={onChange} {...inputProps} />
          )}
        </InputMask>
      </div>
      <Button disabled={isLoading} onClick={() => checkRoom(room)}>
        Войти
      </Button>
      <div className={styles.footer}>
        Внимание! Проверочный код придет на Ваш e-mail только после того, как приемная комиссия в училище начнет собеседование!
      </div>
    </Modal>
  )
}

SecureModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  user: PropTypes.object,
  authData: PropTypes.object,
  setUserData: PropTypes.func,
}

export default SecureModal
