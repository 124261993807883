import React, { useState, useEffect } from "react";
import * as moment from "moment";
import "moment/locale/ru";
import { connect } from "react-redux";
import ScreenSizeContainer from "../../utils/ScreenSizeContainer";
import LeftTitles from "../../utils/LeftTitles";
import styles from "./PC.module.css";
import { Link } from "react-router-dom";
import { resetAuthDataWorker } from "../../../model/common/Auth/actions";
import { fetchUserData, setUserData } from "../../../model/common/User/actions";
import {
  fetchProfileInfo,
  fetchDocuments,
  fetchDocHistory,
  docHistoryReset,
  setProfilePhoto,
  removeFile,
  uploadFile,
} from "../../../model/common/ProfileInfo/actions";
import photoPlug from "../../../images/man.png";
import { api } from "../../../App";
import { BASE_PARAMS } from "../../../constants/api";
import Modal from "../../utils/Modal";
import { Alert, Button } from "shards-react";
import SecureModal from "./Modals/SecureCode";
import Account from "./Account";

const mapStateToProps = (state) => ({
  user: state.user,
  authData: state.auth,
  orgList: state.orgList,
  profileInfo: state.profileInfo.info,
  docHistory: state.profileInfo.doc_history,
});

const mapDispatchToProps = {
  resetAuthDataWorker,
  fetchUserData,
  setUserData,
  setProfilePhoto,
  fetchProfileInfo,
  fetchDocHistory,
  docHistoryReset,
  fetchDocuments,
  uploadFile,
  removeFile
};

const ProfileContainer = ({
  resetAuthDataWorker,
  fetchUserData,
  fetchProfileInfo,
  fetchDocHistory,
  fetchDocuments,
  downloadFile,
  removeFile,
  uploadFile,
  docHistoryReset,
  user,
  setProfilePhoto,
  authData,
  docHistory,
  profileInfo,
  orgList,
  setUserData,
}) => {
  const [photo, setPhoto] = useState(photoPlug);
  const [logo, setLogo] = useState(null);
  const [gouName, setGouName] = useState("");
  const [secureModal, setSecureModal] = useState(null);
  const [rulesModal, setRulesModal] = useState(false);
  const [accountDisplay, setAccountDisplay] = useState(false);

  const own_organization =
    orgList && orgList.find((item) => authData.base === item.Name);
  const dateExam = user && user.exam_date && user.exam_date.split(" ")[0];

  const params = {
    ...BASE_PARAMS,
    base: authData.base,
    applicant: authData.applicant,
    otp: authData.otp,
  };

  useEffect(() => {
    fetchUserData();
    fetchProfileInfo();
  }, []);

  useEffect(() => {
    getPhoto();

    api
      .get("/", { params: { ...params, action: "GOU_Name" } })
      .then((response) => setGouName(response.data.Answer.Data[0].value))
      .catch((err) => console.log(err));

    api
      .get("/", {
        params: { ...params, action: "kiosklogo" },
        responseType: "arraybuffer",
      })
      .then((response) =>
        Buffer.from(response.data, "binary").toString("base64")
      )
      .then((base64) => {
        if (base64) {
          setLogo("data:image/jpeg;base64," + base64);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getPhoto = () => {
    api
      .get("/", {
        params: { ...params, action: "applicant.ownphoto" },
        responseType: "arraybuffer",
      })
      .then((response) =>
        Buffer.from(response.data, "binary").toString("base64")
      )
      .then((base64) => {
        if (base64) {
          setPhoto("data:image/jpeg;base64," + base64);
        }
      })
      .catch((err) => console.log(err));
  };

  const openModal = () => {
    setSecureModal(true);
  };

  const closeModal = () => {
    setSecureModal(false);
  };

  const openModalRules = () => {
    setRulesModal(true);
  };

  const closeModalRules = () => {
    setRulesModal(false);
  };

  return (
    <ScreenSizeContainer>
      <div className={styles.container}>
        <LeftTitles />
        {user && (
          <div className={styles.profile_outer}>
            {accountDisplay ? (
              <Account
                setAccountDisplay={setAccountDisplay}
                fetchDocHistory={fetchDocHistory}
                docHistory={docHistory}
                docHistoryReset={docHistoryReset}
                photo={photo}
                setPhoto={setProfilePhoto}
                uploadFile={uploadFile}
                removeFile={removeFile}
                authData={authData}
                getPhoto={getPhoto}
                logo={logo}
                gouName={gouName}
                info={profileInfo}
              />
            ) : (
              <div className={styles.profile}>
                <div
                  className={styles.photo}
                  style={{ backgroundImage: "url(" + photo + ")" }}
                />
                <div className={styles.profile_inner}>
                  <div className={styles.top}>
                    <div className={styles.fio}>
                      {user.Child_LastName} &nbsp;
                      {user.Child_FirstName} &nbsp;
                      {user.Child_MidName}
                    </div>
                    <div className={styles.orgname}>
                      {own_organization && own_organization.Title}
                    </div>
                  </div>
                  <div className={styles.bottom}>
                    <div className={styles.bottom_inner}>
                      {dateExam && profileInfo?.ApplicantTypeExam !== 'Очно' ? (
                        <div className={styles.clock}>
                          {moment(dateExam, "DD.MM.YYYY H:mm:ss").format(
                            "dddd DD MMMM YYYY"
                          )}{(user?.service != '' && user?.service != 0) ? ' - онлайн-собеседование' : ' - тестовое подключение'}
                          {user.start_time}
                        </div>
                      ) : 
                        profileInfo?.ApplicantTypeExam === 'Очно'
                        ?
                        ''
                        :
                      (
                        <Alert theme="light">Собеседование не назначено</Alert>
                      )}
                      <div className={"mb-2"}>
                        <Button
                          onClick={() => setAccountDisplay(true)}
                          outline
                          block
                        >
                          ЛИЧНЫЙ КАБИНЕТ
                        </Button>
                        {/* <Link to={"/testing"} className={styles.testing_link}>
                          <Button outline block>
                            Проверочный тест
                          </Button>
                        </Link> */}
                        {dateExam && profileInfo?.ApplicantTypeExam === 'Очно' && (
                          <Button onClick={() => openModal()} block>
                            Собеседование
                          </Button>
                        )}
                        <Button
                          onClick={resetAuthDataWorker}
                          theme="danger"
                          block
                        >
                          Выход
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={styles.submenu}>
              <div>
                <a
                  href="/policy.html"
                  target="_blank"
                  className={styles.submenu_link}
                >
                  Политика конфиденциальности
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className={styles.submenu_link}
                  onClick={() => openModalRules()}
                >
                  Правила проведения
                </a>
              </div>
              {/* <div>
                <a onClick={resetAuthDataWorker} className={styles.submenu_link}>
                  Выход
                </a>
              </div> */}
            </div>
            <div className={styles.sub_phone}>
              <p>Телефон горячей линии</p>
              <a href="tel:88003331565" className={styles.submenu_tel}>
                8-800-333-1565
              </a>
            </div>
          </div>
        )}
      </div>
      {secureModal && (
        <SecureModal
          closeModal={closeModal}
          authData={authData}
          setUserData={setUserData}
          user={user}
        />
      )}
      {rulesModal && (
        <Modal closeModal={closeModalRules}>
          <h3>
            Правила проведения онлайн-собеседования, если Методикой определен
            удаленный режим прохождения вступительных испытаний:
          </h3>
          <p>
            Каждый кандидат заранее получит сообщение на e-mail, указанный в
            заявлении на поступление, с датой и временем проведения
            онлайн-собеседования. А в назначенный день за 5 минут до
            назначенного времени — специальный код, который нужно будет ввести
            перед началом видеосеанса.
          </p>
          <p>
            В случае нарушения связи онлайн-собеседование будет остановлено.
            После этого будет назначена новая дата проведения
            онлайн-собеседования. Поэтому просим Вас заранее убедиться в
            надежности предоставления услуг Internet.
          </p>
          <p>
            Чтобы Вам было легче подготовить будущего воспитанника к
            собеседованию, мы предусмотрели раздел «Проверочный тест», где
            разместили примерные вопросы и сохранили внешний вид окна проведения
            реального онлайн-собеседования. Рекомендуем пройти «Проверочный
            тест» перед проведением основного онлайн-собеседования по
            расписанию.
          </p>
          <p>
            {" "}
            Для начала онлайн-собеседования необходимо перейти в раздел
            «Собеседование» и ввести код из электронного письма, которое Вы
            получите непосредственно перед началом онлайн-собеседования.
          </p>
        </Modal>
      )}
    </ScreenSizeContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
