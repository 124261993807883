import {api} from "../../../../App";
import {PROFILE_INFO_REQUEST, DOCUMENTS_REQUEST, DOC_HISTORY_REQUEST, DOC_HISTORY_RESET, START, SUCCESS, ERROR} from "../../../../constants/action-types";
import {BASE_PARAMS} from "../../../../constants/api";

const profileInfoRequestStart = () => ({
    type: `${PROFILE_INFO_REQUEST}${START}`,
});

const profileInfoRequestSuccess = (data) => ({
    type: `${PROFILE_INFO_REQUEST}${SUCCESS}`,
    payload: data
});

const documentsRequestSuccess = (data) => ({
    type: `${DOCUMENTS_REQUEST}${SUCCESS}`,
    payload: data
});

const docHistoryRequestSuccess = (data) => ({
    type: `${DOC_HISTORY_REQUEST}${SUCCESS}`,
    payload: data
});

 export const docHistoryReset = (data) => ({
    type: `${DOC_HISTORY_RESET}`
});

const profileInfoRequestError = (err) => ({
    type: `${PROFILE_INFO_REQUEST}${ERROR}`,
    payload: err
});


export const fetchProfileInfo = () => async (dispatch, getState) => {

    const authData = getState().auth;
    dispatch(profileInfoRequestStart());

    const params = {
        ...BASE_PARAMS,
        action: 'applicant.info',
        base: authData.base,
        applicant: authData.applicant,
        otp: authData.otp
    };

    await api.get('/', {params})
        .then(res => {
            console.log(res.data.Answer.Data_)
            dispatch(profileInfoRequestSuccess(res.data.Answer.Data_))
        })
        .catch(err => {
            dispatch(profileInfoRequestError(err))
        })
};

export const fetchDocuments = () => async (dispatch, getState) => {

    const authData = getState().auth;
    // dispatch(profileInfoRequestStart());

    const params = {
        ...BASE_PARAMS,
        action: 'applicant.documents',
        base: authData.base,
        applicant: authData.applicant,
        otp: authData.otp
    };

    await api.get('/', {params})
        .then(res => {
            console.log(res.data.Answer.Data)
            dispatch(documentsRequestSuccess(res.data.Answer.Data))
        })
        .catch(err => {
            console.log(err)
        })
};

export const fetchDocHistory = (id) => async (dispatch, getState) => {

    const authData = getState().auth;
    // dispatch(profileInfoRequestStart());

    const params = {
        ...BASE_PARAMS,
        action: 'applicant.document.history',
        base: authData.base,
        applicant: authData.applicant,
        otp: authData.otp,
        document: id
    };

    await api.get('/', {params})
        .then(res => {
            console.log(res.data.Answer.Data)
            dispatch(docHistoryRequestSuccess(res.data.Answer.Data))
        })
        .catch(err => {
            console.log(err)
        })
};

export const setProfilePhoto = (file) => async (dispatch, getState) => {
    const authData = getState().auth;
    // dispatch(profileInfoRequestStart());

    const params = {
        ...BASE_PARAMS,
        action: "applicant.apply.photo",
        base: authData.base,
        applicant: authData.applicant,
        id: authData.applicant,
        otp: authData.otp,
    };

    let fd = new FormData()
    fd.append("photo", file);

    await api.post("/", fd, {params})
    .then((res) => console.log(res))
    .catch((err) => console.log(err))
}

export const uploadFile = (file, id, name) => async (dispatch, getState) => {
    const authData = getState().auth;
    // dispatch(profileInfoRequestStart());

    const params = {
        ...BASE_PARAMS,
        action: "applicant.document.upload",
        base: authData.base,
        applicant: authData.applicant,
        document: id,
        otp: authData.otp,
        filename: name
    };

console.log(name)

    let fd = new FormData()
    fd.append("child_doc64", file)
    // fd.append("filename", name)

    await api.post("/", fd, {params})
    .then((res) => dispatch(fetchProfileInfo()))
    .catch((err) => console.log(err))
}

export const removeFile = (id, version) => async (dispatch, getState) => {
    const authData = getState().auth;
    // dispatch(profileInfoRequestStart());

    const params = {
        ...BASE_PARAMS,
        action: "applicant.document.remove",
        base: authData.base,
        applicant: authData.applicant,
        document: id,
        version: version,
        otp: authData.otp,
    };

    await api.get("/", {params})
    .then((res) => dispatch(fetchProfileInfo()))
    .catch((err) => console.log(err))
}