import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../Testing/Testing.module.css'
import repeat from '../../../../images/repeat.svg'
import { Button } from 'shards-react'

const TicketNumber = ({ openTask, generateTask, generateNumber, discipline }) => {
  return (
    <div className={styles.ticket_wrapper}>
      <div className={styles.ticket_number}>
        <h4>Билет №</h4>
        <span className={styles.generate_number}>{generateNumber}</span>
        <div onClick={() => generateTask(1, 100)} className={styles.btn_repeat}>
          <img src={repeat} alt='' />
          Другой билет
        </div>
        <div className={styles.ticket_list}>
          {discipline &&
            discipline.map(item => (
              <div className={styles.ticket_item}>
                <p>{item.discipline}</p>
                <span>4 задания</span>
              </div>
            ))}
        </div>
      </div>
      <Button onClick={() => openTask()}>НАЧАТЬ</Button>
    </div>
  )
}

TicketNumber.propTypes = {}

export default TicketNumber
