import {ERROR, SENT_MESSAGES, START, SUCCESS} from "../../../../constants/action-types";
import {BASE_PARAMS} from "../../../../constants/api";
import {api} from "../../../../App";

export const SentTaskStart = () => ({
    type: `${SENT_MESSAGES}${START}`
});

export const SentTaskSuccess = (data) => ({
    type: `${SENT_MESSAGES}${SUCCESS}`,
    payload: data
});

export const SentTaskError = (data) => ({
    type: `${SENT_MESSAGES}${ERROR}`,
    payload: data
});


export const sentMessages = (count, messagesArray) => (dispatch, getState) => {

    const authData = getState().auth;
    const taskList = getState().task.list;
    const userData = getState().user;

    dispatch(SentTaskStart());

    const params = {
        ...BASE_PARAMS,
        base: authData.base,
        otp: authData.otp,
        applicant: authData.applicant,
        action: "applicant.restorequestion",
        question: taskList ? taskList[count-1].id_question : '',
        schedule: userData.schedule_id,
    };

    api.get('/', {params})
        .then(response => {
            console.log(response)
            dispatch(SentTaskSuccess(response.data.Answer.Data));
        })
        .catch(err => {
            console.log(err)
            dispatch(SentTaskError(err.message));
        });
}