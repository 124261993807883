import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchOrgList } from '../../../model/common/Organizations/actions'

const mapStateToProps = state => ({
  orgList: state.orgList,
})

const mapDispatchToProps = {
  fetchOrgList,
}

const Layout = ({ fetchOrgList, orgList, children }) => {
  useEffect(() => {
    if (!orgList.length) {
      fetchOrgList()
    }
  }, [orgList])

  return children
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
