import { ERROR, START, SUCCESS, TASK_REQUEST } from '../../../../constants/action-types'
import { BASE_PARAMS } from '../../../../constants/api'
import { api } from '../../../../App'

export const TaskRequestStart = () => ({
  type: `${TASK_REQUEST}${START}`,
})

export const TaskRequestSuccess = data => ({
  type: `${TASK_REQUEST}${SUCCESS}`,
  payload: data,
})

export const TaskRequestError = data => ({
  type: `${TASK_REQUEST}${ERROR}`,
  payload: data,
})

export const fetchTask = () => (dispatch, getState) => {
  const user = getState().user
  const task = getState().task.list
  const auth = getState().auth

  if (!user && !auth) {
    dispatch(TaskRequestError('Ошибка данных пользователя'))
    return
  }

  if (!user?.schedule_id) return

  dispatch(TaskRequestStart())

  const params = {
    ...BASE_PARAMS,
    action: 'applicant.questions',
    base: auth.base,
    applicant: auth.applicant,
    otp: auth.otp,
    schedule: user.schedule_id,
  }

  api
    .get('/', { params })
    .then(response => {
      console.log(response)
      const result = {
        list: response.data.Answer.Data,
        search: {
          page: 1,
          maxResult: 1,
          count: response.data.Answer.Data.length,
          pageCount: response.data.Answer.Data.length,
        },
      }
      dispatch(TaskRequestSuccess(result))
    })
    .catch(err => {
      console.log(err)
      dispatch(TaskRequestError(err.message))
    })
}
