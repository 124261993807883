import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from '../../../pages/Enrollment/QuestionTypes/QT.module.css';
import {Button} from "shards-react";


const SelectType = ({ answers, sendAnswer }) => {

    const [answer, setAnswer] = useState(answers[0] && answers[0].answer_id);

    const onChange = (e) => {
        setAnswer(e.target.value);
    };

    const onSend = () => {
        sendAnswer({
            answers: JSON.stringify([{answer_id: answer}])
        })
    };

    return (
        <div>
            {answers.map(item =>
                <div className={styles.radio}>
                    <label className={styles.label}>
                        <input type="radio"
                               value={item.answer_id}
                               onChange={onChange}
                               checked={answer === item.answer_id}/>
                        <span className={styles.answer_text}>{item.answer_text}</span>
                    </label>
                </div>
            )}
            <Button onClick={onSend} className={'mt-2'}>Ответить</Button>
        </div>
    );
};

SelectType.propTypes = {};

export default SelectType;