import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, FormSelect} from "shards-react";
import styles from "../../../pages/Testing/TaskTesting/tasktesting.module.css";

const ClassType = ({answers, sendAnswer, data, question, zones}) => {

    const [answers_id, setClassData] = useState(zones.map(item => ({zone: item.link, answer_id: ''})));

    console.log(answers_id)

    const onChange = (e, index) => {
        const updateState = [...answers_id];
        updateState[index].answer_id = e.target.value;
        setClassData(updateState);
    };

    const onSend = () => {
        sendAnswer({
            answers: JSON.stringify(answers_id)
        })
    };

    return (
        <div>
            {zones.map((item, index) =>
                <div>
                    <div>
                     {item.text}
                    </div>
                    <div>
                         <FormSelect className={styles.task_select} onChange={(e) => onChange(e, index)}>
                             <option value=''>Выбрать</option>
                             {answers.map(item =>
                                <option value={item.answer_id}>{item.answer_text}</option>
                            )}
                        </FormSelect>
                    </div>
                </div>
            )}
            <Button onClick={onSend}>Ответить</Button>
        </div>
    );
};

ClassType.propTypes = {};

export default ClassType;