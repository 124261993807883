import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, FormInput} from "shards-react";
import styles from '../tasks.module.css';

const TaskList = ({answers, sendAnswer}) => {

    //[{zone="{id примера}",answer:"ответ"}...]

    const [answer, setAnswer] = useState(answers.map(item => ({zone: item.link, answer: ''})));

    const onChange = (e, index) => {
        const updatedState = [...answer];
        updatedState[index].answer = e.target.value;

        setAnswer(updatedState);
    };

    const onSend = () => {
        sendAnswer({
            answers: JSON.stringify(answer)
        })
    };


    return (
        <div>
            {answers.map((item, index) =>
                <div className={styles.order_input}>
                    <div className={'mb-1'}>{item.text}</div>
                    <FormInput type={'text'}
                               placeholder={'Введите ответ'}
                               value={answer[index].answer}
                               onChange={(e) => onChange(e, index)}
                               className={'mb-2'}/>
                </div>
            )}
            <Button onClick={onSend} className={'mt-2'}>Ответить</Button>
        </div>
    );
};

TaskList.propTypes = {};

export default TaskList;