import {SUCCESS, TASK_REQUEST} from "../../../../constants/action-types";

const initialState = {
    list: ''
}


export const TaskReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${TASK_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
}

