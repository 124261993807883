import {ORG_LIST_REQUEST, SUCCESS} from "../../../../constants/action-types";

const initialState = [];


export const OrganizationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${ORG_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};