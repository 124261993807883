import React, { useEffect, useState } from 'react'
import styles from '../Testing/Testing.module.css'
import WebRtc from './WebRtc'
import { connect } from 'react-redux'
import ScreenSizeContainer from '../../utils/ScreenSizeContainer'
import { fetchTask } from '../../../model/pages/Enrollment/actions/task'
import Task from './Task'
import { BASE_PARAMS } from '../../../constants/api'
import { api } from '../../../App'
import { Link, useHistory } from 'react-router-dom'
import { fetchUserData } from '../../../model/common/User/actions'
import Spinner from '../../utils/Spinner'
import TaskControls from './TaskControls'
import Header from './Header'
import ChooseTicket from './ChooseTicket'
import TicketNumber from './TicketNumber'
import { sentMessages } from '../../../model/common/sentAnswers/actions/sent'
import { createUUID } from '../../../helpers/createUUID'

const mapStateToProps = state => ({
  userData: state.user,
  taskList: state.task.list,
  authData: state.auth,
  orgList: state.orgList,
  messageList: state.messages,
})

const mapDispatchToProps = {
  fetchUserData,
  fetchTask,
  sentMessages,
}

const EnrollmentContainer = ({ fetchTask, taskList, authData, userData, fetchUserData, orgList, sentMessages, messageList }) => {
  let history = useHistory()

  if (!userData) {
    history.push('/profile')
  }

  const uuid = createUUID()

  const [count, setCount] = useState(1)
  const [question, setQuestion] = useState(null)
  const [loader, setLoader] = useState(null)
  const [loaderGenerateTicket, setLoaderGenerateTicket] = useState(false)
  const [showTask, setShowTask] = useState(false)
  const [ticket, setTicket] = useState(false)
  const [generateNumber, setGenerateNumber] = useState(false)
  const [webSocket, setWebSocket] = useState(null)
  const [showTicket, setShowTicket] = useState(false)
  const [discipline, setDiscipline] = useState(null)
  const own_organization = orgList && orgList.find(item => authData.base === item.Name)

  const nextTask = () => {
    if (count < taskList.length + 1) {
      setCount(count + 1)
    }
  }

  const prevTask = () => {
    if (count >= 1) {
      setCount(count - 1)
    }
  }

  useEffect(() => {
    fetchTask()
  }, [fetchTask])

  useEffect(() => {
    fetchUserData()
  }, [])

  const getAnswer = questionid => {
    const params = {
      ...BASE_PARAMS,
      base: authData.base,
      otp: authData.otp,
      applicant: authData.applicant,
      action: 'applicant.questionView',
      question: questionid,
    }
    api
      .get('/', { params })
      .then(response => {
        setQuestion(response.data.Answer.Data[0])
        setLoader(false)
      })
      .catch(err => {
        setLoader(false)
      })
    setLoader(true)
    setShowTask(true)
  }

  const messageArray = taskList ? new Array(taskList.length).fill([]) : []
  const sendAnswer = additionalParams => {
    const params = {
      ...BASE_PARAMS,
      action: 'applicant.sendanswer',
      base: authData.base,
      otp: authData.otp,
      applicant: authData.applicant,
      schedule: userData.schedule_id,
      question: taskList[count - 1].id_question,
      ...additionalParams,
    }

    api
      .get('/', { params })
      .then(response => {
        console.log(response)
        nextTask()
        if (webSocket) {
          webSocket.send(
            JSON.stringify({
              count: count,
              answer: taskList[count - 1].id_question,
              uuid: uuid,
              room: connect_code,
            })
          )
        }
      })
      .catch(err => console.log(err))

    sentMessages(count)
    messageArray[count].push(messageList)
    console.log(messageArray)
  }

  const openTicket = () => {
    setShowTicket(true)
    getDiscipline()
    generateTask(1, 100)
    setTicket(true)
  }

  const openTask = () => {
    sendTicket()
    setTicket(false)
  }

  const generateTask = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    let result = Math.floor(Math.random() * (max - min)) + min //Максимум не включается, минимум включается
    setGenerateNumber(result)
  }

  const sendTicket = () => {
    api
      .get(
        `/?action=applicant.maketicket&base=${authData.base}&otp=${authData.otp}&applicant=${authData.applicant}&schedule=${userData.schedule_id}&number=${generateNumber}`
      )
      .then(response => {
        fetchUserData()
        fetchTask()
        setLoaderGenerateTicket(false)
        if (webSocket) {
          webSocket.send(
            JSON.stringify({
              ticket: generateNumber,
              uuid: uuid,
              room: connect_code,
            })
          )
        }
      })
      .catch(err => {
        setLoaderGenerateTicket(false)
      })
    setLoaderGenerateTicket(true)
  }

  const getDiscipline = () => {
    const params = {
      // ?action=applicant.disciplines&base=pansion2&otp=4817&applicant=920&format=json
      ...BASE_PARAMS,
      action: 'applicant.disciplines',
      base: authData.base,
      otp: authData.otp,
      applicant: userData.applicant_id,
    }

    api
      .get('/', { params })
      .then(res => {
        setDiscipline(res.data.Answer.Data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (taskList && count <= taskList.length) {
      getAnswer(taskList[count - 1].id_question)
    }
  }, [count, taskList])

  const connect_code = userData && authData && authData.base + '_' + userData.schedule_id + '_' + userData.access_code

  console.log(taskList, count)

  return (
    <ScreenSizeContainer noPadding>
      {userData && (
        <>
          <Header data={userData} organization={own_organization} />
          <div className={styles.container}>
            <div className={styles.left}>
              <WebRtc connect_code={connect_code} setWebSocket={setWebSocket} uuid={uuid} />
            </div>
            <div className={styles.right}>
              <div className={styles.task}>
                <div className={styles.task_wrapper}>
                  {userData && userData.ticket_number === '' && !showTicket && <ChooseTicket openTicket={openTicket} />}
                  {ticket && (
                    <TicketNumber
                      discipline={discipline}
                      openTask={openTask}
                      generateTask={generateTask}
                      generateNumber={generateNumber}
                    />
                  )}
                  {loaderGenerateTicket && (
                    <div className={styles.spinner_container}>
                      <Spinner />
                      Формирование вопросов билета...
                    </div>
                  )}
                  {userData && userData.ticket_number !== '' && showTask && (
                    <div className={styles.taskContainer}>
                      {taskList &&
                        question &&
                        count <= taskList.length &&
                        taskList.map(
                          item =>
                            item.q_order == count && (
                              <Task
                                data={item}
                                ticketNumber={userData.ticket_number}
                                count={count}
                                question={question}
                                sendAnswer={sendAnswer}
                                loader={loader}
                              />
                            )
                        )}
                      {count > taskList.length && (
                        <div className={styles.last_task}>
                          <div className={styles.last_question}>
                            <p>Вы ответили на все вопросы дистанционного собеседования!</p>
                            <p>Ожидайте психологического тестирования.</p>
                          </div>
                        </div>
                      )}
                      <TaskControls data={taskList} count={count} prevTask={prevTask} nextTask={nextTask} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ScreenSizeContainer>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentContainer)
