import React from 'react';
import styles from "../../pages/Home/Home.module.css";

const LeftTitles = ({}) => {
    return (
        <div className={styles.left}>
            <h3 className={styles.h3}>Министерство обороны Российской Федерации</h3>
            <h2 className={styles.h2}>Вперёд, кадет!</h2>
        </div>
    );
};


export default LeftTitles;