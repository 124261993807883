import React from 'react'
import PropTypes from 'prop-types'
import styles from './SSC.module.css'
import background from '../../../images/bg.jpg'
import classNames from 'classnames'
import { version } from '../../../constants/other'



const ScreenSizeContainer = ({ children, noPadding = false }) => {
  return (
    <div
      className={classNames(
        {
          [styles.container]: true,
          [styles.noPadding]: noPadding,
        },
        styles.bg_cover
      )}
      style={{ backgroundImage: 'url(' + background + ')' }}
    >
      {children}
    <div className={styles.version}>{version}</div>
    </div>
  )
}

ScreenSizeContainer.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool,
}

export default ScreenSizeContainer
