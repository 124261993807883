import React from 'react'
import PropTypes from 'prop-types'
import styles from './TaskCotrols.module.css'
import { Progress } from 'shards-react'
import { Link } from 'react-router-dom'

const TaskControls = ({ data, nextTask, prevTask, count }) => {
  console.log(data)
  return (
    <div className={styles.task_progress}>
      <div className={styles.progressbar}>
        <Progress value={(100 / (data.length + 1)) * count}></Progress>
      </div>
      <div className={styles.progressInfo}>
        {count > 1 && (
          <div className={styles.progressInfo_button} onClick={() => prevTask()}>
            Назад
          </div>
        )}
        <div className={styles.progressInfo_current}>
          Задание {count} / {data.length + 1}
        </div>
        {count < data.length + 1 && (
          <div className={styles.progressInfo_button} onClick={() => nextTask()}>
            Вперёд
          </div>
        )}
      </div>
      {data[0].type_task && (
        <Link to={'/'} className={styles.end_test}>
          Закончить тестирование
        </Link>
      )}
    </div>
  )
}

TaskControls.propTypes = {
  data: PropTypes.array,
}

export default TaskControls
