import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from "shards-react";

import styles from '../tasks.module.css';

const Order = ({words, sendAnswer, link, zones}) => {

    // [{answer_id:"ответов",answer:"4"},
    // {zone:"zone1",answer_id:"как",answer:"1"},
    // {zone:"zone1",answer_id:"восемь",answer:"3"},
    // {zone:"zone1",answer_id:"все",answer:"2"},
    // {zone:"zone1",answer_id:"все",answer:"2"},
    // {zone:"zone1",answer_id:"отправлять",answer:"5"},
    // {zone:"zone1",answer_id:"",answer:"}]

    const [availableWords, setAvailableWords] = useState([...words]);
    const [answers, setAnswers] = useState([]);

    const addAnswerItem = (index) => {
        setAnswers([...answers, {...availableWords[index]}]);
        const updatedAvailableWords = [...availableWords];
        updatedAvailableWords.splice(index, 1);
        setAvailableWords(updatedAvailableWords);
    };

    const removeAnswerItem = (index) => {
        setAvailableWords([...availableWords, {...answers[index]}]);
        const updatedAvailableWords = [...answers];
        updatedAvailableWords.splice(index, 1);
        setAnswers(updatedAvailableWords);
    };


    const onSend = () => {
        sendAnswer({
            answers: JSON.stringify(answers.map((item, index) => ({
                zone: link,
                answer_id: item.answer_id,
                answer: index + 1
            })))
        })
    };

    return (
            <div>
                <div className={styles.wordsContainer}>
                    {availableWords.map((item, index) =>
                        <div className={styles.wordOrderItem} key={item.answer_id} onClick={() => addAnswerItem(index)}>
                            {item.answer_text}
                        </div>
                    )}
                </div>
                <div className={styles.answersContainer}>
                    {answers.map((item, index) =>
                        <div className={styles.wordOrderItem} key={item.answer_id} onClick={() => removeAnswerItem(index)}>
                            {item.answer_text}
                        </div>
                    )}
                </div>

                <Button onClick={onSend}>Ответить</Button>
            </div>
    );
};

Order.propTypes = {
    words: PropTypes.array.isRequired,
    sendAnswer: PropTypes.func.isRequired,
};

export default Order;