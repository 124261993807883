import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Container.module.css';

const Container = ({children, flexible = false}) => {
    return (
        <div className={classNames(styles.container, {
            [styles.flex]: flexible
        })}>
            { children }
        </div>
    );
};

Container.propTypes = {
    children: PropTypes.node,
    flexible: PropTypes.bool,
};

export default Container;