import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from "../../../pages/Testing/TaskTesting/tasktesting.module.css";
import {Button, FormTextarea, FormInput} from "shards-react";

const Solution = ({sendAnswer}) => {

    const [dataAnswer, setDataAnswer] = useState();
    const [dataSolution, setDataSolution] = useState();


    const onChangeAnswer = (e) => {
        setDataAnswer(e.target.value);
    }
    const onChangeSolution = (e) => {
        setDataSolution(e.target.value);
    }
    const onSend = () => {
        sendAnswer({
            answers: {answer: dataAnswer, solution: dataSolution}
        });
    }
    return (
        <div className={styles.resolve_type}>
            <FormTextarea  type={'text'}
                       placeholder={'Решение'}
                       className={'mb-2 mt-2'}
                       onChange={(e) => onChangeSolution(e)}
            />
            <FormInput type={'text'}
                       placeholder={'Введите ответ'}
                       onChange={(e) => onChangeAnswer(e)}
            />
            <Button onClick={() => onSend()} className={'mt-2'}>Ответить</Button>
        </div>
    );
};

Solution.propTypes = {
    
};

export default Solution;