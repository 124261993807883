import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './tasktesting.module.css';
import {FormInput, Button, FormCheckbox, FormSelect} from "shards-react";

const TaskTesing = ({data, question, children, answer}) => {

    const translateContainer = useRef(null);
    const [checkBox, setCheckBox] = useState(false);
    // translateContainer.current.innerHTML = data.question.text;

    return (

        <div className={styles.task_wrapper}>
            <div>
                <div className={styles.crump}>{data.task_lesson.lesson_name}</div>
                <div className={styles.task_info}>
                    <h4>Задание {data.task_order}</h4>
                </div>
                <div className={styles.task_item}>
                    <div className={styles.task_question}>{question.text_question}</div>
                    {data.type_task === 'image' &&
                        <div className={styles.img_center}>
                            <img src={data.task_question.img_question} alt="img"/>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.answer_wrapper}>
                {
                    (data.type_task == 'text_input') ?
                        <div className={styles.answer_send}>
                            <FormInput type={'text'} placeholder={'Введите ответ'}/>
                        </div> :
                    (data.type_task == 'checkbox') ?
                        answer.map(item =>
                            <div className={styles.task_checkbox}>
                                <div className={styles.flex}>
                                    <span>{item.id})</span>
                                    <p>{item.answer_text}</p>
                                </div>
                                <FormCheckbox
                                    className={styles.checkbox_transparent}
                                />
                            </div>) :
                    (data.type_task == 'select') ?
                        answer.map(item =>
                            <div>
                                <div>
                                    {item.task_text}
                                </div>
                                <div>
                                    <FormSelect className={styles.task_select}>
                                            <option value=''>Выбрать</option>
                                        {item.task_answer.map(item =>
                                            <option value=''>{item.answer_text}</option>
                                        )}
                                    </FormSelect>
                                </div>
                            </div>) :
                    (data.type_task == 'order') ?
                        <div className={styles.order_container}>
                            {answer.map(item =>
                                <div className={styles.task_order}>
                                    <span>{item.id}) </span>
                                    {item.answer_text}
                                </div>
                            )}
                            <div className={styles.answer_send}>
                                <FormInput type={'text'} placeholder={'Введите ответ'}/>
                            </div>
                        </div> :
                    (data.type_task == 'order_input') ?
                        answer.map(item =>
                            <div className={styles.order_input}>
                                <div>{item.answer_text}</div>
                                <FormInput type={'text'} placeholder={'Введите ответ'}/>
                            </div>
                        ) :
                    (data.type_task == 'input') ?
                        answer.map(item =>
                        <div className={styles.input_type}>
                            <div>{item.answer_text}</div>
                            <FormInput type={'text'} placeholder={'Введите ответ'}/>
                        </div>
                        ) :
                    (data.type_task == 'image') ?
                        <div className={styles.resolve_type}>
                        {answer.map(item =>
                            <>
                                <div>{item.answer_text}</div>
                                <div className={styles.answer_tooltip}>{item.answer_tooltip}</div>
                            </>
                        )}
                            <FormInput type={'text'} placeholder={'Введите ответ'} className={'mt-2'}/>
                        </div>  :
                        (data.type_task == 'resolve') ?
                            <div className={styles.resolve_type}>
                                {answer.map(item =>
                                    <>
                                        <div>{item.answer_text}</div>
                                        <div className={styles.answer_tooltip}>{item.answer_tooltip}</div>
                                    </>
                                )}
                                <FormInput type={'text'} placeholder={'Решение'} className={'mb-2 mt-2'}/>
                                <FormInput type={'text'} placeholder={'Введите ответ'}/>
                            </div>  :
                    (data.type_task == 'wordorder_input') ?
                        <div className={styles.wordorder}>
                            <div className={'mb-1'}>Пример: 0.name is what your?</div>
                            <FormInput type={'text'}
                                       placeholder={'Введите ответ'}
                                       value={'What is your name'}
                                       className={'mb-2'}/>
                            {answer.map(item =>
                                (item.answer_text &&
                                <div className={'mb-2'}>
                                <div className={'mb-1'}>{item.answer_text}</div>
                                <FormInput type={'text'} placeholder={'Введите ответ'}/>
                                </div>)
                            )}
                        </div>
                        :
                        (data.question_type == 'translate') ?
                            <div>
                                <div ref={translateContainer}></div>
                                {data.zones.map(item =>
                                    <FormSelect className={styles.task_select}>
                                        {item.answers.map(item =>
                                            <option value=''>{item.answer_text}</option>
                                        )}
                                    </FormSelect>

                                )}
                            </div> :
                            (data.question_type == 'wordorder_select') ?
                                <div>
                                    <h4>Mice</h4>
                                    <p>Once upon a
                                    <select>
                                        <option value="">___</option>
                                        <option value="">time</option>
                                        <option value="">day</option>
                                        <option value="">month</option>
                                    </select>
                                        there lived mice in the house. Their
                                    <select>
                                        <option value="">___</option>
                                        <option value="">life</option>
                                        <option value="">live</option>
                                        <option value="">leave</option>
                                    </select>
                                        was very happy because there was always a lot of
                                    <select>
                                        <option value="">___</option>
                                        <option value="">eat</option>
                                        <option value="">meal</option>
                                        <option value="">food</option>
                                    </select>
                                    </p>
                                </div>
                    : console.log('err')
                }
            </div>
        </div>
    );
};

TaskTesing.propTypes = {};

export default TaskTesing;