import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

import styles from './Spinner.module.css';

const Spinner = ({height, width}) => (
    <div className={styles.container}>
        <Loader
            type="Oval"
            color="white"
            height={height}
            width={width}
        />
    </div>
);

Spinner.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
};

Spinner.defaultProps = {
    height: 100,
    width: 100,
};

export default Spinner;