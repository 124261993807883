import io from 'socket.io-client/dist/socket.io'
import * as RTCMultiConnection from 'rtcmulticonnection/dist/RTCMultiConnection'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import styles from './video.module.css'
import { Button } from 'shards-react'
import { Link } from 'react-router-dom'

let bytesPrev = 0
let timestampPrev = 0

const WebRtc = ({ connect_code, setWebSocket, uuid }) => {
  window.io = io

  let localVideo
  let localStream
  let remoteVideo
  let remoteVideoBg
  let peerConnection
  let serverConnection

  const SCREEN_SHARING_CONNECT_CODE = connect_code + '_screen'

  let peerConnectionConfig = {
    iceServers: [
      { urls: 'stun:stun.edumil.ru:5349' },
      {
        urls: 'turn:turn.edumil.ru:5349?transport=tcp',
        credential: 'ceasar',
        username: 'edumil',
      },
      {
        urls: 'turn:turn.edumil.ru:5349?transport=udp',
        credential: 'ceasar',
        username: 'edumil',
      },
    ],
  }

  const [completionScreen, setCompletionScreen] = useState(false)
  const [bitrate, setBitrate] = useState(0)

  let screenSharingConnection = useMemo(() => {
    //захват экрана
    let con = new RTCMultiConnection()
    con.socketURL = 'https://webrtc.dnevnik-lms.ru:9001/'
    con.socketMessageEvent = 'screen-sharing-demo'
    con.autoCreateMediaElement = false
    con.session = {
      oneway: true,
      screen: true,
    }
    con.sdpConstraints.mandatory = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: true,
    }
    con.iceServers = peerConnectionConfig.iceServers

    return con
  }, [])

  function pageReady() {
    localVideo = document.getElementById('localVideo')
    remoteVideo = document.getElementById('remoteVideo')
    remoteVideoBg = document.getElementById('remoteVideoBg')

    serverConnection = new WebSocket('wss://webrtc.dnevnik-lms.ru')
    serverConnection.onmessage = gotMessageFromServer
    setWebSocket(serverConnection)
    serverConnection.onclose = e => {
      console.log(e)
      setTimeout(() => {
        pageReady()
      }, 1000)
    }

    serverConnection.onerror = e => {
      console.log(e)
      serverConnection.close()
    }

    let constraints = {
      video: true,
      audio: true,
    }

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(constraints).then(getUserMediaSuccess).catch(errorHandler)
    } else {
      alert('Your browser does not support getUserMedia API')
    }
  }

  function getUserMediaSuccess(stream) {
    localStream = stream
    localVideo.srcObject = stream
  }

  function start(isCaller) {
    //console.log(connect_code);
    //console.log(localStream);
    peerConnection = new RTCPeerConnection(peerConnectionConfig)
    peerConnection.onicecandidate = gotIceCandidate
    peerConnection.ontrack = gotRemoteStream
    peerConnection.addStream(localStream)
    peerConnection.onremovestream = e => {
      console.log('onremovestream', e)
    }
    peerConnection.onconnectionstatechange = function (event) {
      switch (peerConnection.connectionState) {
        case 'connected':
          console.log(peerConnection.connectionState)
          // The connection has become fully connected
          break
        case 'disconnected':
          closeConnection()
          setCompletionScreen(true)
          console.log(peerConnection.connectionState)
          break
        case 'failed':
          closeConnection()
          console.log(peerConnection.connectionState)
          // One or more transports has terminated unexpectedly or in an error
          break
        case 'closed':
          console.log(peerConnection.connectionState)
          // The connection has been closed
          break
      }
    }

    if (isCaller) {
      peerConnection.createOffer().then(createdDescription).catch(errorHandler)
    }
  }

  const createScreenCapture = () => {
    try {
      screenSharingConnection.open(SCREEN_SHARING_CONNECT_CODE)
    } catch (e) {
      console.log('Screen sharing connection failed', e)
    }
  }

  const closeScreenCapture = () => {
    screenSharingConnection.close()
  }

  function closeConnection() {
    if (peerConnection) {
      peerConnection.close()
    }

    serverConnection.close()
    localStream.getTracks().forEach(track => track.stop())
  }

  const join = froom => {
    if (serverConnection.readyState === 1) {
      serverConnection.send(JSON.stringify({ join: froom }))
    }
  }

  function gotMessageFromServer(message) {
    if (!peerConnection) start(false)

    let signal = JSON.parse(message.data)

    console.log(signal)

    // Ignore messages from ourself
    if (signal.uuid == uuid) return

    if (signal.requestScreenSharing) {
      createScreenCapture()
    }

    if (signal.sdp) {
      peerConnection
        .setRemoteDescription(new RTCSessionDescription(signal.sdp))
        .then(function () {
          // Only create answers in response to offers
          if (signal.sdp.type == 'offer') {
            peerConnection.createAnswer().then(createdDescription).catch(errorHandler)
          }
        })
        .catch(errorHandler)
    } else if (signal.ice) {
      peerConnection.addIceCandidate(new RTCIceCandidate(signal.ice)).catch(errorHandler)
    }
  }

  function gotIceCandidate(event) {
    if (event.candidate != null) {
      serverConnection.send(JSON.stringify({ ice: event.candidate, uuid: uuid, room: connect_code }))
    }
  }

  function createdDescription(description) {
    console.log('got description')

    peerConnection
      .setLocalDescription(description)
      .then(function () {
        serverConnection.send(
          JSON.stringify({
            sdp: peerConnection.localDescription,
            uuid: uuid,
            room: connect_code,
          })
        )
      })
      .catch(errorHandler)
  }

  function gotRemoteStream(event) {
    console.log('got remote stream')
    remoteVideoBg.srcObject = event.streams[0]
    remoteVideo.srcObject = event.streams[0]

    setInterval(() => {
      getRemoteStats()
    }, 1200)
  }

  function errorHandler(error) {
    console.log(error)
  }

  const getRemoteStats = () => {
    peerConnection.getStats(null).then(results => {
      results.forEach(report => {
        const now = report.timestamp
        //console.log(report)
        let bitrate
        if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
          const bytes = report.bytesReceived
          if (timestampPrev) {
            bitrate = (8 * (bytes - bytesPrev)) / (now - timestampPrev)
            bitrate = Math.floor(bitrate)
          }
          bytesPrev = bytes
          timestampPrev = now
          if (!isNaN(bitrate)) {
            setBitrate(bitrate)
          }
        }
      })
    })
  }

  useEffect(() => {
    pageReady()

    let interval = setInterval(() => {
      if (localStream) {
        //console.log(localStream)
        join(connect_code)
        start(true)
        clearInterval(interval)
      }
    }, 250)

    return () => {
      closeConnection()
      console.log('closed connection')
    }
  }, [])

  return (
    <div className={styles.container}>
      {completionScreen ? (
        <div className={styles.completion}>
          <div className={styles.completion_title}>Собеседование завершено, ожидайте результатов</div>
          <Link to={'/profile'}>
            <Button theme='success'>Вернуться в профиль</Button>
          </Link>
        </div>
      ) : (
        <>
          <video id='localVideo' autoPlay muted className={styles.own} />
          <video id='remoteVideo' autoPlay className={styles.interlocutor} />
          <video id='remoteVideoBg' autoPlay className={styles.videoBg} />
          <div className={styles.videoLayer}>Битрейт: {bitrate} Kbps</div>
        </>
      )}
    </div>
  )
}

WebRtc.propTypes = {
  connect_code: PropTypes.string.isRequired,
}

export default WebRtc
