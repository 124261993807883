import React from 'react'
import PropTypes from 'prop-types'
import styles from './ChooseTicket.module.css'
import { Button } from 'shards-react'

const ChooseTicket = ({ openTicket }) => {
  return (
    <div className={styles.choose_ticket}>
      <div>
        <h2>Вступительные испытания 2024</h2>
        <p>
          Формирование варианта задания для каждого кандидата происходит автоматически без участия человека. <br />
          Все электронные материалы и видеозапись ответов кандидата поступают в базу данных LMS Школа.
        </p>
      </div>
      <Button onClick={() => openTicket()}>ВЫБОР БИЛЕТА</Button>
    </div>
  )
}

ChooseTicket.propTypes = {
  openTicket: PropTypes.func,
}

export default ChooseTicket
