import {SENT_MESSAGES, SUCCESS} from "../../../../constants/action-types";

const initialState = {
    messages: ''
}


export const SentAnswerReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${SENT_MESSAGES}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
}

