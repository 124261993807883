import React, {useState} from 'react';
import {Button, FormSelect} from "shards-react";

const Check = ({sendAnswer, zones, question}) => {

    const [zone, setZone] = useState(zones.map(item => ({zone: item.link, answer_id: ''})));

    // check answers=[{zone:"{id варианта},answer_id:"1-да,2-нет,3-неизвестно"},...]
    // для каждого варианта свой элемент массива
    const onChange = (e, index) => {
        const target = e.target;
        const updatedState = [...zone];
        updatedState[index].answer_id = target.value;
        setZone(updatedState)
    }

    const onSend = () => {
        sendAnswer({
            answers: JSON.stringify(zone)
        });
    }

    return (
        <div>
            {zones &&
                zones.map((item, index) =>
                    <div>
                        <div className={'mb-1'}>{item.text}</div>
                        <FormSelect onChange={(e) => onChange(e, index)}>
                            <option value="">Выбрать</option>
                            <option value="1">Да</option>
                            <option value="2">Нет</option>
                            <option value="3">Неизвестно</option>
                        </FormSelect>
                    </div>
                )
            }
            <Button onClick={onSend} className={'mt-2'}>Ответить</Button>
        </div>
    );
};

export default Check;