import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, FormTextarea} from "shards-react";
import styles from "../../../pages/Testing/TaskTesting/tasktesting.module.css";

const Questionlist = ({sendAnswer, answers}) => {

    const [solution, setSolution] = useState(answers.map(item => ({zone: item.link, solution: ''})));

    const onChange = (e, index) => {
        const updatedState = [...solution];
        updatedState[index].solution = e.target.value;

        setSolution(updatedState);
    };

    const onSend = () => {
        sendAnswer({
            answers: JSON.stringify(solution)
        })
    };

    return (
        <div>
            {answers.map((item, index) =>
                <div className={styles.order_input}>
                    <div className={'mb-1'}>{item.text}</div>
                    <FormTextarea
                        type={'text'}
                        placeholder={'Введите ответ'}
                        onChange={(e) => onChange(e, index)}
                        value={solution[index].answer}
                        className={'mb-2'}
                    />
                </div>
            )}
            <Button onClick={() => onSend()}>Ответить</Button>
        </div>
    );
};

Questionlist.propTypes = {};

export default Questionlist;