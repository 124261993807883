import React, { useState, useEffect } from "react";
import Modal from "../../utils/Modal";
import styles from "./PC.module.css";
import nodoc from "../../../images/no_doc.png";
import norate from "../../../images/no_rate.png";
import {
  IoIosTrash,
  IoIosAttach,
  IoIosInformationCircleOutline,
  IoIosImage,
} from "react-icons/io";
import { BASE_URL } from "../../../constants/api";
import moment from "moment/moment";

export default function Account({
  setAccountDisplay,
  photo,
  getPhoto,
  setPhoto,
  authData,
  logo,
  uploadFile,
  removeFile,
  info,
  gouName,
  docHistory,
  docHistoryReset,
  fetchDocHistory,
}) {
  const [documentItem, setDocmentItem] = useState(null);
  const [doc, setDoc] = useState(null);

  useEffect(() => {
    setDocmentItem(docHistory);
  }, [docHistory]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event, id = null) => {
    const file = event.target.files[0];
    const name = event.target.files[0].name

    const base64file = (await convertBase64(file)).split(",")[1];
    if (id && name) {
      uploadFile(base64file, id, name);
    } else {
      await setPhoto(base64file);
      getPhoto();
    }
  };

  const selectDoc = (item) => {
    fetchDocHistory(item.id_document);
    setDoc(item);
  };

  const examDateTime = info.info[0].exam_date.split(" ")
  const createDateTime = info.info[0].Date.split(" ")
  console.log(examDateTime)

  return (
    <div className={styles.accWrapper}>
      {documentItem && (
        <Modal closeModal={() => docHistoryReset()}>
          <div className={styles.documentModal}>
            <div className={styles.modalTitle}>
              История документа:
              <br />
              <a
                href={`${BASE_URL}/?format=json&action=applicant.document.download&base=${authData.base}&applicant=${authData.applicant}&otp=${authData.otp}&document=${doc.id_document}&version=${doc.id_version}`}
              >
                <b>{documentItem[0].DocName}</b>
              </a>
            </div>
            <table>
              <thead>
                <tr>
                  <th style={{width: "150px"}}>Дата и время присвоения статуса</th>
                  <th>Статус</th>
                  <th>Причина</th>
                  <th>Прикрепленный файл</th>
                </tr>
              </thead>
              <tbody>
                {documentItem.map((item, i) => (
                  <tr key={`${item.DocName}_${i}`}>
                    <td>{item.updated}</td>
                    <td>{item.status_desc}</td>
                    <td>{item.Description}</td>
                    <td>{item.attachment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
      <div className={styles.accHeader}>
        <div className={styles.accLogo}>
          <img src={logo} alt="o_O" />
        </div>
        <p>
          Федеральное государственное казенное общеобразовательное учреждение
          <br />
          <b>{`"${gouName}"`}</b>
        </p>
      </div>
      {info.info[0] && (
        <div className={styles.accDescription}>
          <div className={styles.descr}>
            <div className={styles.descrPhoto}>
              <img src={photo} alt="o_O" />
            </div>
            <div>
              <label>
                <IoIosImage size={20} />
                <input
                  type="file"
                  onChange={(e) => handleFileRead(e)}
                  accept="image/png, image/jpeg"
                />
              </label>
            </div>
          </div>

          <div className={styles.descrFirstRow}>
            <div className={styles.firstRowItem}>
              <span>№ личного дела: </span>
              <span>{info.info[0].nomer}</span>
            </div>
            <div className={styles.firstRowItem}>
              <span>Дата создания: </span>
              <span>{createDateTime.length > 1 && `${createDateTime[0]}, ${createDateTime[1].slice(0,-3)}`}</span>
            </div>
          </div>
          <div className={styles.descrOtherRow}>
            <span>Статус личного дела кандидата:</span>
            <span>{info.info[0].status}</span>
          </div>
          {/* <div className={styles.descrOtherRow}>	
            <span>Допуск:</span>	
            <span	
              style={{	
                color:	
                  info.info[0].dopusk?.split(" ")[0].toLowerCase() === "не"	
                    ? "red"	
                    : info.info[0].dopusk?.split(" ")[0].toLowerCase() === "на"	
                    ? "#e69600"	
                    : "#24ce3b",	
                textTransform: "uppercase",	
              }}	
            >	
              {info.info[0].dopusk}	
            </span>	
          </div>
          {info.info[0].CauseFailure && (	
            <div className={styles.descrOtherRow}>	
              <span>Причина отказа:</span>	
              <span style={{color: "grey"}}>{info.info[0].CauseFailure}</span>	
            </div>	
          )} */}
          <div className={styles.examResults}>	
        <div className={styles.examResTitle}>	
          Результаты вступительных испытаний:	
        </div>	
        <div className={styles.noResultBlock}>
            Результаты будут опубликованы на сайте &nbsp; 
            <a href="https://mil.ru/">Министерства обороны Российской Федерации</a>
        </div>
        {/* {info.exams &&	
        info.exams.length &&	
        (info.exams.filter((it) => it.grade).length ||	
          info.bonus.filter((it) => it.bonus_grade).length ||	
          info.info[0].Ball ||	
          info.info[0].psycho) ? (	
          <div className={styles.resultBlocks}>	
            <div className={styles.examResBlock}>	
              {info.exams.map((it) => (	
                <div className={styles.examResItem}>	
                  <span>{it.Exam}</span>	
                  <span style={{color: Number(it.grade)>=Number(it.pass_grade) ? "#24ce3b" : "red"}}>{it.grade}</span>	
                </div>	
              ))}	
            </div>	
            <div className={styles.examResBlock}>	
              {info.info[0].psycho && (	
                <div className={styles.examResItem}>	
                  <span>Итоговое заключение о психологической готовности</span>	
                  <span>{info.info[0].psycho}</span>	
                </div>	
              )}	
              {info.bonus.length &&	
                info.bonus.map((it) => (	
                  <div className={styles.examResItem}>	
                    <span>{it.bonus_name}</span>	
                    <span>{it.bonus_grade}</span>	
                  </div>	
                ))}	
            </div>	
            <div className={styles.examTotalBlock}>	
              <div>ЕДИНАЯ БАЛЛЬНАЯ ОЦЕНКА</div>	
              <div>{info.info[0].Ball}</div>	
            </div>	
          </div>	
        ) : (	
          <div className={styles.docsNotification}>	
            <img src={norate} alt="o_O" />	
            <div>	
              Пока тут ничего нет, но после прохождения вступительных испытаний	
              здесь появятся их результаты.	
            </div>	
          </div>	
        )}	 */}
      </div>
          <div className={styles.descrOtherRow}>
            <span>Экзаменационная группа:</span>
            <span>{info.info[0].group_name}</span>
          </div>
          {/* <div className={styles.descrOtherRow}>
            <span>Дата проведения вступительных испытаний:</span>
            <span>{examDateTime.length > 1 && `${examDateTime[0]}, ${examDateTime[1].slice(0,-3)}`}</span>
          </div> */}
          <div className={styles.descrOtherRow}>
            <span>Класс для поступления:</span>
            <span>{info.info[0].Child_Class}</span>
          </div>
          <div className={styles.descrOtherRow}>
            <span>Контактный телефон:</span>
            <span>{info.info[0].NotificationPhone}</span>
          </div>
          <div className={styles.descrOtherRow}>
            <span>E-mail:</span>
            <span>{info.info[0].email}</span>
          </div>
          <div className={styles.descrOtherRow}>
            <span>Адрес для переписки:</span>
            <span>{info.info[0].post_address}</span>
          </div>
        </div>
      )}
      <div className={styles.accDocuments}>
        <div>Не хватает документов:</div>
        {info.documents && info.documents.length ? (
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th>Название документа</th>
                <th>Причина</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody>
              {info.documents.map((item, i) => (
                <tr key={`${item.DocName}_${i}`}>
                  <th>{i + 1}</th>
                  <td>{item.DocName}</td>
                  <td>{item.description}</td>
                  <td>{item.status_desc}</td>
                  <td className={styles.buttonsCell}>
                    {(item.status_desc === "Отправлен" ||
                      item.status_desc === "Принят" || item.status_desc === "Отклонен") && (
                      <IoIosInformationCircleOutline
                        onClick={() => selectDoc(item)}
                        title="История документа"
                        size={20}
                      />
                    )}
                    {(item.status_desc === "Не хватает" ||
                      item.status_desc === "Отклонен") && (
                      <label>
                        <IoIosAttach title="Загрузить файл" size={20} />
                        <input
                          type="file"
                          onChange={(e) =>
                            handleFileRead(e, item.id_document)
                          }
                        />
                      </label>
                    )}
                    {item.status_desc === "Отправлен" && (
                      <IoIosTrash
                        onClick={() =>
                          removeFile(item.id_document, item.id_version)
                        }
                        title="Удалить файл"
                        size={20}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.docsNotification}>
            <img src={nodoc} alt="o_O" />
            <div>
              Если у приемной комиссии возникнут вопросы по документам, в этом
              разделе появится список документов, которых не хватает, и Вы
              сможете прикрепить их сканкопии. Регулярно посещайте свой личный
              кабинет.
            </div>
          </div>
        )}
      </div>
      <button
        className={styles.backButton}
        onClick={() => setAccountDisplay(false)}
      >
        Назад
      </button>
    </div>
  );
}
