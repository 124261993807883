import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from "shards-react";

import styles from '../tasks.module.css';

const WordOrderType = ({sendAnswer, zones, questions}) => {

    const [availableWords, setAvailableWords] = useState(zones.map(item => item.answers));
    const [answers, setAnswers] = useState(zones.map(() => []));

    // answers =[{zone:"предложение",answer_id:"слово", answer:"№ слова в предложении"}.. ]
    const addAnswerItem = (item, shmindex, index) => {
        const updatedAvailableWords = [...availableWords];
        updatedAvailableWords[shmindex].splice(index, 1);
        setAvailableWords(updatedAvailableWords);
        const updatedAnswers = [...answers];
        updatedAnswers[shmindex].push(item);
    };

    const removeAnswerItem = (item, shmindex, index) => {
        const updatedAvailableWords = [...answers];
        updatedAvailableWords[shmindex].splice(index, 1);
        setAnswers(updatedAvailableWords);
        const updatedAnswers = [...availableWords];
        updatedAnswers[shmindex].push(item);
    };

    const onSend = () => {
        sendAnswer({
            answers: JSON.stringify(
                answers.map((item, shmindex) => (
                    answers[shmindex].map((item, index) =>
                        ({
                            zone: zones[shmindex].link,
                            answer_id: item.answer_id,
                            answer: index + 1
                        }))
                ))
            )
        })
    };

    return (
        <div>
            <ol className={styles.wordOrderTasksList}>
                {questions &&
                zones.map((item, shmindex) =>
                    <li key={shmindex} className={styles.wordOrderContainer}>
                        <div className={styles.wordsContainer}>
                            {availableWords[shmindex].map((item, index) =>
                                <div className={styles.wordOrderItem} key={item.answer_id}
                                     onClick={() => addAnswerItem(item, shmindex, index)}>
                                    {item.answer_text}
                                </div>
                            )}
                        </div>
                        <div className={styles.answersContainer}>
                            {answers[shmindex].map((item, index) =>
                                <div className={styles.wordOrderItem} key={item.answer_id}
                                     onClick={() => removeAnswerItem(item, shmindex, index)}>
                                    {item.answer_text}
                                </div>
                            )}
                        </div>
                    </li>
                )}
            </ol>

            <Button onClick={onSend}>Ответить</Button>
        </div>
    );
};

WordOrderType.propTypes = {
    sendAnswer: PropTypes.func.isRequired,
};

export default WordOrderType;