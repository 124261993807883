import React, { useEffect, useRef } from 'react'

import styles from './Task.module.css'
import classNames from 'classnames'

import SelectTask from '../../../utils/Tasks/Select'
import TaskList from '../../../utils/Tasks/TaskList'
import SimpleType from '../../../utils/Tasks/Simple'
import Questionlist from '../../../utils/Tasks/QuestionList'
import ClassType from '../../../utils/Tasks/Class'
import Solution from '../../../utils/Tasks/Solution'
import WordOrderType from '../../../utils/Tasks/WordOrder'
import Translate from '../../../utils/Tasks/Translate'
import Check from '../../../utils/Tasks/Check'
import Fill from '../../../utils/Tasks/Fill'
import Order from '../../../utils/Tasks/Order'

const Task = ({ data, question, sendAnswer, loader }) => {
  const { question_type, answers, zones } = question

  console.log(question)

  const questionContainer = useRef(null)

  useEffect(() => {
    if (question) {
      questionContainer.current.innerHTML = question.question_text
    }
  }, [question])

  const QuestionContainer = () => {
    switch (question_type) {
      case 'select':
        return <SelectTask answers={answers} sendAnswer={sendAnswer} />
      case 'tasklist':
        return <TaskList sendAnswer={sendAnswer} answers={zones} />
      case 'simple':
        return <SimpleType sendAnswer={sendAnswer} />
      case 'wordorder':
        return <WordOrderType sendAnswer={sendAnswer} questions={question} zones={zones} />
      case 'order':
        return <Order sendAnswer={sendAnswer} link={zones[0].link} words={zones[0].answers} zones={zones} />
      case 'questionlist':
        return <Questionlist sendAnswer={sendAnswer} answers={zones} />
      case 'class':
        return <ClassType sendAnswer={sendAnswer} question={question} answers={answers} zones={zones} />
      case 'solution':
        return <Solution sendAnswer={sendAnswer} />
      case 'translate':
        return <Translate sendAnswer={sendAnswer} zones={zones} />
      case 'check':
        return <Check sendAnswer={sendAnswer} zones={zones} />
      case 'fill':
        return <Fill sendAnswer={sendAnswer} zones={zones} question={question} />
      default:
        return <div>Такой вопрос еще не обрабатывается</div>
    }
  }

  return (
    <div>
      <div className={styles.task_wrapper}>
        <div>
          <div className={styles.title_block}>
            <div className={styles.task_discipline}>{data.Lesson}</div>
            <div className={styles.task_info}>
              <h4>Задание {data.q_order}</h4>
            </div>
          </div>
          <div className={styles.task_item}>
            <div className={styles.task_details}>
              {loader && <div>Загрузка</div>}

              <div
                ref={questionContainer}
                className={classNames({
                  [styles.transparent]: loader,
                })}
              />
            </div>
          </div>
        </div>
        <div className={styles.answer_wrapper}>
          <QuestionContainer />
        </div>
      </div>
    </div>
  )
}

export default Task
