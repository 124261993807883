import {RESET_AUTH_DATA, SET_AUTH_DATA} from "../../../../constants/action-types";


export const setAuthData = (data) => ({
   type: SET_AUTH_DATA,
   payload: data
});

export const resetAuthData = () => ({
   type: RESET_AUTH_DATA
});

export const resetAuthDataWorker = () => (dispatch) => {
    localStorage.setItem('base', '');
    localStorage.setItem('applicant', '');
    localStorage.setItem('otp', '');

    dispatch(resetAuthData());
};
