import {api} from "../../../../App";
import {ERROR, ORG_LIST_REQUEST, START, SUCCESS} from "../../../../constants/action-types";
import {BASE_PARAMS} from "../../../../constants/api";

const orgListRequestStart = () => ({
    type: `${ORG_LIST_REQUEST}${START}`,
});

const orgListRequestSuccess = (data) => ({
    type: `${ORG_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

const orgListRequestError = (err) => ({
    type: `${ORG_LIST_REQUEST}${ERROR}`,
    payload: err
});


export const fetchOrgList = () => (dispatch, getState) => {

    dispatch(orgListRequestStart());

    const params = {
        ...BASE_PARAMS,
        action: 'databases',
        'MO_Schools': '1',
    };

    api.get('/', {params})
        .then(res => {
            dispatch(orgListRequestSuccess(res.data.Answer.Data));
        })
        .catch(err => {
            dispatch(orgListRequestError(err))
        })
};