import React from 'react';
import PropTypes from 'prop-types';
import LessonTimer from "../LessonTimer";
import styles from "./Header.module.css";

const Header = ({organization, data}) => {
    return (
        <header className={styles.header}>
            <div>
                <div className={styles.orgName}>{organization && organization.Title}</div>
                <div className={styles.fio}>
                    {data.Child_LastName} &nbsp;
                    {data.Child_FirstName} &nbsp;
                    {data.Child_MidName}
                </div>
            </div>
            <div className={styles.header_right}>
                <LessonTimer/>
            </div>
        </header>
    );
};

Header.propTypes = {
    data: PropTypes.array,
    organization: PropTypes.object
};

export default Header;