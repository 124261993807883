import {
  PROFILE_INFO_REQUEST,
  DOCUMENTS_REQUEST,
  DOC_HISTORY_REQUEST,
  DOC_HISTORY_RESET,
  SUCCESS,
} from "../../../../constants/action-types";

const initialState = {
  info: null,
  documents: null,
  doc_history: null,
};

export const ProfileInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${PROFILE_INFO_REQUEST}${SUCCESS}`:
      return {
        ...state,
        info: action.payload 
        };
    case `${DOCUMENTS_REQUEST}${SUCCESS}`:
      return {
        ...state,
        documents: action.payload
        };
    case `${DOC_HISTORY_REQUEST}${SUCCESS}`:
      return {
        ...state,
        doc_history: action.payload 
      };
    case `${DOC_HISTORY_RESET}`:
      return {
        ...state,
        doc_history: null 
        };
    default:
      return state;
  }
};
