import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, FormInput, FormSelect} from "shards-react";


const Translate = ({sendAnswer, zones}) => {

    const [zone, setZone] = useState(zones.map(item => ({zone: item.link, answer_id: '', answer: ''})));

    // answers=[
    // {zone:"id позиции в тексте",
    // answer_id:"{при выборе из списка id варианта}",
    // answer:"введенный или выбранный текст"
    // }, ...]

    const onChange = (e, index) => {
        const target = e.target;
        const updatedState = [...zone];
        updatedState[index].answer = target.value;
        updatedState[index].answer_id = target.options ? target.options[target.selectedIndex].text : '';
        setZone(updatedState);
    };

    const onChangeSelect = (e, index) => {
        const target = e.target;
        const updatedState = [...zone];
        updatedState[index].answer_id = target.value;
        updatedState[index].answer = target.options ? target.options[target.selectedIndex].text : '';
        setZone(updatedState);
    };

    const onSend = () => {
        sendAnswer({
            answers: JSON.stringify(zone)
        });
    }


    return (
        <div>
            {zones.map((item, index) => (
                <div>
                    <div className={'mb-1'}>{item.text}</div>
                    {(item.answers.length != 0) ?
                        <FormSelect className={'mb-2'} onChange={(e) => onChangeSelect(e, index)}>
                            <option value=''>Выбрать</option>
                            {item.answers.map(item =>
                                <option value={item.answer_id}>{item.answer_text}</option>
                            )}
                        </FormSelect> :
                        <FormInput
                            placeholder={'Введите ответ'}
                            className={''}
                            type={'text'}
                            onChange={(e) => onChange(e, index)}/>
                    }
                </div>
            ))}
            <Button onClick={() => onSend()} className={'mt-2'}>Ответить</Button>
        </div>
    );
};

Translate.propTypes = {};

export default Translate;