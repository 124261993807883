import {RESET_AUTH_DATA, SET_AUTH_DATA} from "../../../../constants/action-types";


const initialState = {
    base: localStorage.getItem('base'),
    applicant: localStorage.getItem('applicant'),
    otp: localStorage.getItem('otp'),
};


export const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_DATA:
            return action.payload;

        case RESET_AUTH_DATA:
            return {
                base: null,
                applicant: null,
                otp: null
            };

        default:
            return state;
    }
};